import { useTranslation } from 'react-i18next'
import Link from 'next/link'

export default function PageNotFound() {
  const { t } = useTranslation('errorPages')

  return (
    <>
      <h1 className="nj-h1">{t('page_not_found.title')}</h1>
      <p className="margin-bottom-2">
        {t('page_not_found.message_content_description')}
      </p>
      <p className="margin-bottom-2">
        {t('page_not_found.message_content_url_info')}
      </p>
      <Link className="usa-button" href="/" key="returnToHome">
        {t('page_not_found.visit_homepage_button')}
      </Link>
    </>
  )
}
